Molapp.BrManager = (function() {

    var BRS = ['xsmall', 'small', 'smedium', 'medium', 'xmedium', 'large'];

    function getCurrent() {
        return window.getComputedStyle($('#br-info')[0],'::after').content.replace(/"/g, '');
    }

    function lte(br) {
        return BRS.indexOf(getCurrent()) <=  BRS.indexOf(br);
    }

    function gte(br) {
        return BRS.indexOf(getCurrent()) >=  BRS.indexOf(br);
    }

    return {
        getCurrent: getCurrent,
        lte: lte,
        gte: gte
    };

})();