Molapp.PatternManager = (function() {


    var $window = $(window),
        conf,
        transitionDelay,
        $mainPatterns,
        currentPattern,
        garbage,
        pool;


    function setup() {
        Zumo.createStateManager('pattern', {
            doIn: function() {
                this.setState(Zumo.StateManagers.STATE_ON);
            },
            doOut: function() {
                var that = this;
                $(this.target).one(Molapp.events.transitionReady, function() {
                    that.setState(Zumo.StateManagers.STATE_OFF);
                });
            }
        });
    }


    function init() {

        var pageDelay = Zumo.props.pageDelay,
            //$footer = $('footer'),

            prepareDefaultTransition = function(context, request) {

                var tConf = context.props.pattern,
                    rt;

                // Modify the current pattern out transition if we are clearing the pattern (no target pattern)
                if (!tConf) {
                    currentPattern.conf.fnOut = "scaleOut";
                    currentPattern.conf.transitionDelay = 10;
                    currentPattern.conf.delay = 1;
                    currentPattern.conf.duration = 300;
                    tConf = {
                        color: 0xFFFFFF,
                        motifs: []
                    };
                }

                rt = getRequestTransition(request);

                tConf.color = normalizeColor(tConf.color);

                if (!rt || !rt.transitionType) {
                    currentPattern.transform(tConf, transitionDelay);
                }

                // Reset the pattern conf if there is was target
                if (!context.props.pattern) {
                    currentPattern.conf.fnOut = Zumo.props.patternConf.fnOut;
                    currentPattern.conf.transitionDelay = Zumo.props.patternConf.transitionDelay;
                    currentPattern.conf.delay = Zumo.props.patternConf.delay;
                    currentPattern.conf.duration = Zumo.props.patternConf.duration;
                }

            };

        Zumo.props.pageDelay = 0; // Set it on 0 for the first page displayed.
        conf = Zumo.props.patternConf;

        garbage = [];
        pool = [];

        transitionDelay = conf.transitionDelay;
        $mainPatterns = $('#main-patterns');

        Zumo.observe('onPageRequest', function(context, request) {

            var rt = getRequestTransition(request),
                displayedPage = Zumo.getDisplayedPage(),
                isGteSmall = !Modernizr.touch;//Molapp.BrManager.gte('small');

            if (rt && rt.transitionType == 'box') {
                Zumo.props.pageDelay = 0;
            } else {
                if (isGteSmall) {
                    TweenLite.to(window, pageDelay / 1000, {scrollTo: {y: 0}, ease: Power2.easeOut});
                } else {
                    window.scrollTo(0, 0);
                    $(Zumo.getDisplayedPage().master.target).css('display', 'none');
                }
                if (currentPattern) {
                    prepareDefaultTransition(context, request);
                }
                if (displayedPage && displayedPage.master.mediator && typeof displayedPage.master.mediator.doOut == 'function') {
                    displayedPage.master.mediator.doOut();
                }
            }

            //$footer.hide();

        });

        Zumo.observe('onPageDisplay', function(master) {

            var tConf = master.context.props.pattern,
                $currentPage,
                rt,

                transitionCallback = function() {
                    var doIn = function(tMaster) {
                        if (tMaster.mediator && typeof tMaster.mediator.doIn == 'function') {
                            tMaster.mediator.doIn();
                        }
                    };
                    clean();
                    if ($currentPage) {
                        $currentPage.trigger(Molapp.events.transitionReady);
                    }
                    if (master.mediator) {
                        doIn(master);
                    } else {
                        ZumoAgent.observe(master, "onInit", function(tMaster) {
                            doIn(tMaster);
                        });
                    }
                };

            if (!Molapp.BrManager.gte('small') && tConf) {
                tConf.motifWidth = 50;
                tConf.motifHeight = 50;
                tConf.scale = 0.5;
            }

            if (Zumo.getDisplayedPage()) {
                $currentPage = $(Zumo.getDisplayedPage().master.target);
            }

            if (!currentPattern) {

                createPattern();
                transitionCallback();

            } else {

                rt = getRequestTransition(master.request);

                tConf = tConf || {};
                tConf.color = normalizeColor(tConf.color);

                if (rt && rt.transitionType == 'box') {
                    doBoxTransition(tConf, transitionCallback, rt.$originalTarget)
                } else {
                    transitionCallback();
                }

            }

            //$footer.show();

            Zumo.props.pageDelay = pageDelay;

        }, -1);

    }


    function normalizeColor(c) {
        if (typeof c == 'string') {
            c = parseInt(c, 16);
        }
        return c;
    }


    function getRequestTransition(request) {
        if (!Modernizr.touch && request.params.originalEvent && request.params.originalEvent.target) {
            return {
                $originalTarget: $(request.params.originalEvent.target),
                transitionType: $(request.params.originalEvent.target).attr('data-pattern-transition')
            };
        }
    }


    function clean() {
        var p;
        while(garbage.length) {
            p = garbage.shift();
            p.pause();
            p.$container.hide();
            pool.push(p);
        }
    }


    function markDirty(currentPattern) {
        garbage.push(currentPattern);
    }


    function createPattern(pConf) {
        var $container,
            pattern;
        if (!pool.length) {
            $container = $('<div class="pattern">');
            $mainPatterns.append($container);
            pattern = buildPattern($container, pConf);
        } else {
            pattern = pool.shift();
            pattern.$container.show();
            pattern.reset(pConf);
        }
        setCurrentPattern(pattern);
        return pattern;
    }


    function setCurrentPattern(pattern) {
        if (currentPattern === pattern) {
            return;
        }
        if (currentPattern) {
            markDirty(currentPattern);
        }
        currentPattern = pattern;
    }


    function buildPattern(container, nConf) {

        nConf = nConf || Zumo.getCurrentPage().context.props.pattern;

        var pConf = $.extend({}, conf, nConf);

        if (typeof pConf.color == 'string') {
            pConf.color = parseInt(pConf.color, 16);
        }

        pConf.container = container;
        if (Modernizr.ios && Modernizr.safari) {
            container.parent().height(window.innerHeight + 69);
        }

        return Molapattern.create(pConf);

    }


    function doBoxTransition(tConf, callback, $originalTarget) {

        var $parent = $originalTarget,
            $box,
            $center,
            sConf,
            color,
            p;

        while($parent) {
            if ($parent.hasClass('box')) {
                $box = $parent;
                break;
            }
            $parent = $parent.parent();
        }

        $center = $('.center', $box);
        TweenLite.to($center, 0.5, {y: '150%', ease: Expo.easeIn});

        color = Molapp.Utils.rgb2hex($('.content', $box.parent()).css('background-color'));
        sConf = $.extend({}, conf, {color: color});
        createPattern(sConf);
        currentPattern.transform(tConf);

        currentPattern.$container.addClass('fixed');
        p = $box.offset();
        p.top -= $window.scrollTop();
        currentPattern.$container.css('clip', 'rect(' + p.top + 'px, ' + (p.left + $box.width()) + 'px, ' + p.top + 'px, ' + p.left + 'px)');
        new TimelineMax({delay: 0.5, onComplete: function() {
            window.scrollTo(0, 0);
            currentPattern.$container.removeClass('fixed');
            $center.removeAttr('style'); // Remove all transforms from TweenMax
            callback();
        }})
            .to(currentPattern.$container, 0.15, {css: {clip: 'rect(' + p.top + 'px, ' + (p.left + $box.width()) + 'px, ' + (p.top + $box.height()) + 'px, ' + p.left + 'px)'}})
            .to(currentPattern.$container, 0.15, {css: {clip: 'rect(0px, ' + (p.left + $box.width()) + 'px, ' + (p.top + $box.height()) + 'px, ' + p.left + 'px)'}})
            .to(currentPattern.$container, 0.15, {css: {clip: 'rect(0px, ' + $window.width() + 'px, ' + (p.top + $box.height()) + 'px, ' + p.left + 'px)'}})
            .to(currentPattern.$container, 0.15, {css: {clip: 'rect(0px, ' + $window.width() + 'px, ' + $window.height() + 'px, ' + p.left + 'px)'}})
            .to(currentPattern.$container, 0.15, {css: {clip: 'rect(0px, ' + $window.width() + 'px, ' + $window.height() + 'px, 0px)'}, onComplete: function() {
                currentPattern.$container.removeAttr('style');
            }});

    }


    return {
        setup: setup,
        init: init
    };


})();