Molapp.MenuManager = (function() {


    var WINDOW_D = 100,

        title = document.title,
        isSoftOpen = false,
        $window = $(window),
        $menuLinks,
        $menuButton,
        $menuBack,
        $footerMenu,
        $mobileMenu,
        $site,

        throttle = (function() {
            var timeWindow = 20,
                lastExecution = new Date((new Date()).getTime() - timeWindow),
                fnThrottle = function (args) {
                    doThrottle();
                };
            return function() {
                Molapp.session.scrollTop = $window.scrollTop();
                if ((lastExecution.getTime() + timeWindow) <= (new Date()).getTime()) {
                    lastExecution = new Date();
                    return fnThrottle.apply(this, arguments);
                }
            };
        }());


    function init() {

        $menuButton = $('#menu-button');
        $menuBack = $('#back-button');
        $footerMenu = $('footer.menu');
        $menuLinks = $('li a', $footerMenu);
        $mobileMenu = $('.mobile-nav');
        $site = $('.site');

        $menuButton.click(toggle);
        $window.on('scroll', throttle);

        $menuBack.click(back);
        //$menuLinks.click(onPageRequest);

        Zumo.observe('onPageRequest', onPageRequest);
        Zumo.observe('onPageDisplay', onPageDisplay);

    }

    function onPageRequest() {
        //debugger;
        //var $this = $(this),
        //    href = $this.attr('href'),
        //    to = href.replace('/', '');
        //close(to != Zumo.getCurrentPage().context.id);
        close(true);
    }

    function onPageDisplay(master) {
        document.title = title + ' - ' + master.context.id; //TODO: Set a more readable title
        $menuBack.toggleClass('shown', master.context.id != 'frontpage');
    }

    function back() {
        if (Zumo.getDisplayedPage().request.referrer) {
            window.history.back();
        } else {
            Zumo.go(Zumo.props.defaultPage);
        }
    }

    function isOpen() {
        return $menuButton.hasClass('open');
    }

    function toggle() {
        if (isOpen()) {
            close();
        } else {
            open();
        }
    }

    function open(soft) {
        if (isOpen()) {
            return;
        }
        isSoftOpen = soft;
        isMobileMenu() ? openMobile() : openDefault();
        $menuButton.addClass('open');
        $menuBack.addClass('hidden');
    }

    function close(force) {
        if (!isOpen()) {
            return;
        }
        isMobileMenu() ? closeMobile() : closeDefault(force);
        isSoftOpen = false;
        $menuButton.removeClass('open');
        $menuBack.removeClass('hidden');
    }

    function openDefault() {
        var y;
        if (!isSoftOpen) {
            y = $footerMenu.offset().top - $window.scrollTop();
            $footerMenu.addClass('open');
            TweenMax.to($footerMenu, 0, {y: y});
            TweenMax.to($footerMenu, 0.3, {
                y: 0,
                ease: Quart.easeOut,
                onComplete: function() {
                    $footerMenu.removeAttr('style');
                }
            });
        }
    }

    function closeDefault(force) {
        var y;
        if (force || !isSoftOpen) {
            y = Math.min($window.height(), $site.height() - $window.scrollTop());
            TweenMax.to($footerMenu, 0.3, {
                y: y,
                ease: Quart.easeOut,
                onComplete: function() {
                    $footerMenu.removeClass('open');
                    $footerMenu.removeAttr('style');
                }
            });
        }
        if (!force && $window.scrollTop() > $site.height() - WINDOW_D) {
            TweenMax.to(window, 0.6, {scrollTo: {y: $site.height() - $footerMenu.height()}, ease: Quart.easeOut});
        }
    }

    function openMobile() {
        $mobileMenu.removeClass('hidden');
    }

    function closeMobile() {
        $mobileMenu.addClass('hidden');
    }

    function isMobileMenu() {
        return $footerMenu.css('position') == 'relative';
    }

    function doThrottle() {
        if (!isMobileMenu()) {
            if ($window.scrollTop() > $site.height() - WINDOW_D) {
                open(true);
            } else if (isSoftOpen) {
                close();
            }
        }
    }


    return {
        init: init,
        open: open,
        close: close
    };


})();