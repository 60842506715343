if (Detectizr.browser.name == 'ie' && Detectizr.browser.major < 10
    || Detectizr.os.name == 'ios'  && Detectizr.os.major < 8
    || Detectizr.os.name == 'android'  && Detectizr.os.major < 5) {
    window.location = 'old.html';
}

(function() {

    var isConfLoaded = false,
        isWinLoaded = false,
        isTimeout = false,
        init = function() {
            if (isConfLoaded && isWinLoaded && isTimeout) {
                var $loader = $('#loader');
                TweenLite.to($loader, 0.3, {opacity: 0, onComplete: function() {
                    $loader.remove();
                }});
                Zumo.History.go();
                Molapp.init();
            }
        };

    setTimeout(function() {
        isTimeout = true;
        init();
    }, 1000);

    $(function() {

        PIXI.utils._saidHello = true;

        Molapp.PatternManager.setup();

        Zumo.log.level = 1;
        Zumo.History.goAtLoad = false;
        Zumo.init('zumo.json');
        Zumo.observe('onConfLoaded', function() {

            var S = '@2x',
                loader = PIXI.loader,
                defaultPage = Zumo.props.defaultPage,
                motifs = Zumo.getPageContext(defaultPage).props.pattern.motifs,
                path = Zumo.History.getPath(),
                pageContext,
                s,
                d,
                i,

                addToLoader = function(a, prefix) {
                    for (i = 0; i < a.length; i++) {
                        s = a[i];
                        if (!s) {
                            continue;
                        }
                        if (typeof s == 'object') {
                            s = s.src;
                        }
                        if (!s) {
                            continue;
                        }
                        if (window.devicePixelRatio > 1) {
                            d = s.lastIndexOf('.');
                            if (s.indexOf(S) == -1) {
                                s = s.substr(0, d) + S + s.substr(d);
                            }
                        }
                        loader.add(prefix + i, s);
                    }
                };

            if (path && path != '' && path != defaultPage) {
                pageContext = Zumo.getPageContext(path);
                if (pageContext && pageContext.props.pattern) {
                    motifs = motifs.concat(pageContext.props.pattern.motifs);
                }
            }

            isConfLoaded = true;

            addToLoader(motifs, 'pre');

            loader.once('complete', function() {
                var pages = Zumo._conf.views.pages,
                    pMotifs = [],
                    p,
                    i;
                init();
                for (i = 0; i < pages.length; i++) {
                    p = pages[i];
                    if (p.id != defaultPage && p.id != path && p.props.pattern) {
                        pMotifs = pMotifs.concat(p.props.pattern.motifs);
                    }
                }
                addToLoader(pMotifs, 'post');
                loader.load();
            });
            loader.load();

        });

    });

    $(window).load(function() {
        isWinLoaded = true;
        init();
    });

})();

$(window).on('beforeunload', function() {
    window.scrollTo(0, 0);
});