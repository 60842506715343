Molapp.Views = Molapp.Views || {};

Molapp.Views.Article = function(dom) {
    this.$dom = $(dom);
};

Molapp.Views.Article.prototype = {

    init: function() {
        this.$article = $('article', this.$dom);
    },

    doIn: function() {
        var that = this;
        setTimeout(function() {
            that.$article.removeClass('hidden');
        }, 100);
    },

    doOut: function() {
        this.$article.addClass('hidden');
    }

};