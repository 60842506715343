Molapp.Views = Molapp.Views || {};

Molapp.Views.About = function(dom) {
    this.$dom = $(dom);
};

Molapp.Views.About.prototype = {

    init: function() {

        var $show_contact = $('section.item button', this.$dom);

        $show_contact.click(function() {
            var $contactUs = $('section.contact-us');
            if ($contactUs.length==0) {
                alert('Could not find a contact-us section to scroll to')
            } else {
                var target = $contactUs.offset().top;
                var dist = Math.abs(target-$(window).scrollTop());
                TweenMax.to(window, Math.sqrt(dist*dist)*0.0004, {scrollTo:target, ease:Quart.easeOut})
            }
        });

        TweenMax.to(this.$dom, 0, {y: '100%', ease: Quart.easeOut});

        //Safari hack
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
            setTimeout(function() {
                var links = document.getElementsByTagName("link");
                for (var x in links) {
                    var link = links[x];
                    if (typeof link.getAttribute == "function" && link.getAttribute("type") && link.getAttribute("type").indexOf("css") > -1) {
                        link.href = link.href + "?id=" + new Date().getMilliseconds();
                    }
                }
            }, 500);
        }

    },

    destroy: function() {},

    doIn: function() {
        TweenMax.to(this.$dom, 0.6, {y: '0%', ease: Quart.easeOut});
    },

    doOut: function() {
        TweenMax.to(this.$dom, 0.6, {y: '100%', ease: Quart.easeIn});
    }

};