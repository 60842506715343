Molapp.Views = Molapp.Views || {};

Molapp.Views.Projects = function(dom) {
    this.$dom = $(dom);
};

Molapp.Views.Projects.prototype = {

    init: function() {

        $('li').click(function(){
            $('a', this).click();
            return false;
        });

        this.$li = $('li', this.$dom);

        $('.content.box', this.$dom).on("mouseenter",function(){
            var bgColor = $(this).data('hovercolor');
            if(bgColor){
                $(this).data('orgColor', $(this).css('background-color'));
                var props = {color:$(this).css('background-color')};
                TweenMax.to(props, 0.35,{colorProps:{color:bgColor}, onUpdateParams:[$(this), props], onUpdate:function(t, props){
                    t.css('background-color', props.color);
                }})
            }
        }).on('mouseleave', function(){
            var bgColor = $(this).data('orgColor');
            if(bgColor){
                var props = {color:$(this).css('background-color')};
                TweenMax.to(props, 0.3,{ease:Quart.easeOut, colorProps:{color:bgColor}, onUpdateParams:[$(this), props], onUpdate:function(t, props){
                    t.css('background-color', props.color);
                }})
            }
        });

    },

    doIn: function() {
        this.$li.each(function(i) {
            var that = this;
            setTimeout(function() {
                $(that).removeClass('hidden');
            }, i * 80 + 200);
        });
    },

    doOut: function() {
        var that = this;
        this.$li.each(function(i) {
            var dat = this;
            setTimeout(function() {
                $(dat).addClass('hidden');
            }, (that.$li.length - i) * 50);
        });
    }

};