(function(window) {

    var App = window.Molapp;

    App.Tracker = {

        init: function(zumo) {

            var that = this,
                onViewDisplay = function(master, type) {
                    var uri = master.context.id,
                        _track = master.context.props._track;
                    if ((type == "page" && _track != "false") || (type == "block" && _track == "true")) {
                        if (master.context.props._trackId) {
                            uri = master.context.props._trackId;
                        }
                        that.trackPage(uri);
                    }
                };

            zumo.observe("onPageDisplay", function(master) {
                onViewDisplay(master, "page");
            });

            zumo.observe("onBlockDisplay", function(master) {
                onViewDisplay(master, "block");
            });

        },

        trackPage: function(uri) {
            this.trackPageGA(uri);
        },

        trackEvent: function(category, action, label, value) {
            this.trackEventGA.apply(this, ([].slice.call(arguments)));
        },

        trackPageGA: function(uri) {
            if (typeof ga != "undefined")
                ga('send', 'pageview', {'page': "/" + uri, 'title': uri});
        },

        trackEventGA: function(category, action, label, value) {
            if (typeof ga != "undefined")
                ga('send', 'event', category, action, label, value);
        }

    };

})(this);