Molapp.Views = Molapp.Views || {};

Molapp.Views.Frontpage = function(dom) {
    this.$dom = $(dom);
    // Implementing:
    // this.$window
    // this.throttle
    // this.resize
    // this.aboutPattern
    // this.$boxIntro
};

Molapp.Views.Frontpage.prototype = {

    init: function() {

        var that = this,
            $window = this.$window = $(window),
            windowHeight = $window.height(),
            windowWidth = this.windowWidth = $window.width(),
            yBoxReposition = windowHeight / 2,
            $box = this.$box = $('.description-box', this.$dom),
            boxWidth,
            boxHeight,
            boxHeightDifference,
            $projectContainer = this.$projectContainer = $('.content', $box),
            $parts = $('.part', this.$dom),
            $projectBackground = this.$projectBackground = $('.part.project', this.$dom),
            $articleContainer = this.$articleContainer = $('.part.articles .container', this.$dom),
            $aboutContainer = this.$aboutContainer  = $('.part.about .pattern, .part.about .description', this.$dom),
            $lastPart = $('.part.generosity', this.$dom),
            $cta = $('#cta', this.$dom),
            $lastPartTop,
            $lastPartBottom,
            lastScrollTop = 0,
            direction = 'down',
            currentBoxContent,
            isMobile,

            checkMobile = function(){
                return $box.css('top') == 'auto';
            },

            getCurrentPart = function() {
                var st = $window.scrollTop(),
                    min = 1000,
                    $current;
                $parts.each(function() {
                    var $this = $(this),
                        top = $this.offset().top,
                        e = 0,//direction == 'down' ? windowHeight : windowHeight * -0.3,
                        d = Math.abs((top - e) - st);
                    if (d < min || (top >= st && top + $this.height() <= st + windowHeight)) {
                        $current = $this;
                        min = d;
                    }
                });
                return $current;
            },

            showCurrentBoxContent = function() {
                if (Molapp.BrManager.gte('small')) {
                    var $part = getCurrentPart();
                    if (!$part) {
                        return;
                    }
                    if ($part.hasClass('project')) {
                        showBoxContent($part.attr('data-project'));
                    }
                }
            },

            showBoxContent = (function() {
                var z = 100;
                return function(name) {
                    if (!name || name == currentBoxContent) {
                        return;
                    }
                    var $box,
                        $content,
                        from = {},
                        to;
                    currentBoxContent = name;
                    $box = $('.box.' + name, this.$dom);
                    $content = $('.content', $box);
                    $box.css({
                        'z-index': z++,
                        'visibility': 'visible'
                    });
                    //to = {
                    //    ease: Expo.easeOut,
                    //    onComplete: function () {
                    //        //$boxContents.removeClass('visible');
                    //    }
                    //};
                    if (direction == 'down') {
                        $content.removeClass('middle').addClass('top');
                        setTimeout(function(){
                            $content.addClass('middle').removeClass('top');
                        }, 0);
                        //$content.css('bottom', '');
                        //from.top = '100%';
                        //to.css = {top: '0%'};
                    } else {
                        $content.removeClass('middle').addClass('bottom');
                        setTimeout(function(){
                            $content.addClass('middle').removeClass('bottom');
                        }, 0);
                        //$content.css('top', '');
                        //from.bottom = '100%';
                        //to.css = {bottom: '0%'};
                    }
                    //$content.css(from);
                    //TweenLite.to($content, 1, to);
                    setTimeout(function(){
                        updateBoxSizeHeight();
                    }, 500);
                }
            })(),

            updateBoxPosition = function() {
                if ($window.scrollTop() > yBoxReposition) {
                    $box.addClass('left');
                    if (Molapp.BrManager.gte('small')){
                        if ($window.scrollTop() > $lastPartTop) {
                            $box.css({'position': 'absolute', 'top': $lastPartTop + windowHeight / 2})
                        }
                        else {
                            $box.css({'position': '', 'top': ''})
                        }
                    }
                } else {
                    $box.removeClass('left');
                }
            },
            updateBoxSizeWidth = function() {
                $box.css({'width': ''});
                boxWidth = Math.ceil($box.width());
                if((boxWidth % 2) === 0){
                    boxWidth --;
                }
                $box.width( boxWidth );
            },

            updateBoxSizeHeight = function() {
                boxHeight = $box.height();
                if((boxHeight % 2) !== 0 && Molapp.BrManager.gte('small')) {
                    $('.wrapper', $box).css('transform', 'translateY(0.5px)');
                }
                else{
                    $('.wrapper', $box).css('transform', '');
                }
            },

            throttle = this.throttle = (function() {
                var timeWindow = 40,
                    lastExecution = new Date((new Date()).getTime() - timeWindow),
                    fnThrottle = function (args) {
                        var st = $window.scrollTop();
                        direction = st > lastScrollTop ? 'down' : 'up';
                        lastScrollTop = st;
                        updateBoxPosition();
                        showCurrentBoxContent();
                    };
                return function() {
                    if ((lastExecution.getTime() + timeWindow) <= (new Date()).getTime()) {
                        lastExecution = new Date();
                        return fnThrottle.apply(this, arguments);
                    }
                };
            }()),

            resize = this.resize = function() {
                windowHeight = $window.height();
                windowWidth = $window.width();
                yBoxReposition = windowHeight / 2;
                $lastPartTop = $lastPart.offset().top - (windowHeight - $lastPart.height())/2;
                $lastPartBottom = $lastPart.offset().top + $lastPart.height();
                isMobile = checkMobile();
                updateBoxPosition();
                updateBoxSizeWidth();
                if (Molapp.BrManager.gte('small')) {
                    $projectContainer.removeClass('middle');
                    $('.box', this.$box).css({visibility: ''});
                }
                setTimeout(function(){
                    updateBoxSizeHeight();
                }, 450);
            },

            onContainerClick = this.onContainerClick = function() {
                openContainingLink($(this));
            },

            onBgContainerClick = this.onBgContainerClick = function() {
                $('a[href="' + $(this).data('project') + '"]', $box).click();
                return false;
            },

            onAboutContainerClick = this.onAboutContainerClick = function() {
                openContainingLink($(this).closest('li'));
            };

        $box.css({'position': '', 'top': ''});

        //cta arrow should bounce and when clicked scroll a viewport down
        var $ctaArrow = $cta.find('svg');
        var ctaArrowBounce = TweenMax.from($ctaArrow, 0.6, {y:"+30", yoyo:true, repeat:-1, ease:Cubic.easeOut});
        $cta.on('mousedown', function(){
            TweenMax.killTweensOf($ctaArrow);
            TweenMax.to(window, 0.6, {scrollTo:"+"+window.innerHeight, ease:Cubic.easeOut});
        }).on('mouseenter', function(){
            ctaArrowBounce.pause();
        }).on('mouseleave', function(){
            ctaArrowBounce.resume();
        });

        if (Molapp.BrManager.gte('small')) {
            showBoxContent('intro');
        }

        setTimeout(function(){
            updateBoxSizeHeight();
        }, 900);

        $window.on('scroll', throttle);
        $window.on('resize', resize);
        $window.resize();

        //this.$boxIntro = $('.box.intro', this.$dom);

        function openContainingLink(container){
            $('a', container).click();
            return false;
        }

        $projectContainer.click(onContainerClick);
        $projectBackground.click(onBgContainerClick);
        $articleContainer.click(onContainerClick);
        $aboutContainer.click(onAboutContainerClick);


        //for some reason tweenmax doesn't work for backgroundColor on these. Works fine on other things.. much weirdness
        $projectContainer.on("mouseenter",function(){
            var bgColor = $(this).data('hovercolor');
            if(bgColor){
                $(this).data('orgColor', $(this).css('background-color'));
                var props = {color:$(this).css('background-color')};
                TweenMax.to(props, 0.35,{colorProps:{color:bgColor}, onUpdateParams:[$(this), props], onUpdate:function(t, props){
                    t.css('background-color', props.color);
                }})
            }
        }).on('mouseleave', function(){
            var bgColor = $(this).data('orgColor');
            if(bgColor){
                var props = {color:$(this).css('background-color')};
                TweenMax.to(props, 0.3,{ease:Quart.easeOut, colorProps:{color:bgColor}, onUpdateParams:[$(this), props], onUpdate:function(t, props){
                    t.css('background-color', props.color);
                }})
            }
        });


        //Fix vh issue on touch devices
        if ($('html.touch').length) {
            $projectBackground.height(windowHeight);
            if (!Molapp.BrManager.gte('small')) {
                $box.height(windowHeight);
            }
        }
    },

    destroy: function() {
        this.$box.removeClass('left');
        $('.box', this.$box).removeAttr('style'); // Clean boxes up from styles created on TweenMax
        this.$window.off('scroll', this.throttle);
        this.$window.off('resize', this.resize);
        this.$projectContainer.off('click', this.onContainerClick);
        this.$projectBackground.off('click', this.onBgContainerClick);
        this.$articleContainer.off('click', this.onContainerClick);
        this.$aboutContainer.off('click', this.onAboutContainerClick);
    },

    doIn: function() {
        var that = this,
            $boxes = $('.box', this.$box).not(":first");
        if (Molapp.BrManager.gte('small')){
            $boxes.css({visibility: 'hidden'});
        }
        that.$box.removeClass('hidden');
    },

    doOut: function() {
        this.$box.addClass('hidden');
    }

};