(function(exports) {


    // -- VARIABLES

    var tr = {

            slideIn: function(el) {

                var easing = el.p.conf.easingIn || Expo.easeOut,
                    speed = (el.p.conf.duration || 1000) / 1000,
                    delay = (el.p.conf.delay || 5) / 1000,
                    d = delay * el.p.nEls - delay * el.n;

                el.sprite.position.x -= el.p.conf.motifWidth * 18;
                el.sprite.position.y -= el.p.conf.motifHeight * 18;
                el.sprite.scale.x = el.p.conf.scale;
                el.sprite.scale.y = el.p.conf.scale;
                el.sprite.visible = true;

                TweenLite.to(el.sprite.position, speed, {
                    delay: d,
                    x: el.x,
                    y: el.y,
                    ease: easing
                });

            },

            slideOut: function(el) {

                var easing = el.p.conf.easingOut || Expo.easeIn,
                    speed = (el.p.conf.duration || 1000) / 1000,
                    delay = (el.p.conf.delay || 5) / 1000,
                    d = delay * el.p.nEls - delay * el.n,
                    x = el.x + el.p.conf.motifWidth * 20,
                    y = el.y + el.p.conf.motifHeight * 20;

                TweenLite.to(el.sprite.position, speed, {
                    delay: d,
                    x: x,
                    y: y,
                    ease: easing,
                    onComplete: function () {
                        el.sprite.parent.removeChild(el.sprite);
                        el.sprite.destroy();
                    }
                });

            },

            scaleIn: function(el) {

                var easing = el.p.conf.easingIn || Back.easeOut,
                    speed = (el.p.conf.duration || 1000) / 1000,
                    delay = (el.p.conf.delay || 5) / 1000,
                    d = delay * el.p.nEls - delay * el.n;

                el.sprite.scale.x = 0;
                el.sprite.scale.y = 0;
                el.sprite.visible = true;

                TweenLite.to(el.sprite.scale, speed, {
                    delay: d,
                    x: el.p.conf.scale,
                    y: el.p.conf.scale,
                    ease: easing
                });

            },

            scaleOut: function(el) {

                var easing = el.p.conf.easingIn || Expo.easeIn,
                    speed = (el.p.conf.duration || 1000) / 1000,
                    delay = (el.p.conf.delay || 5) / 1000,
                    d = delay * el.p.nEls - delay * el.n;

                TweenLite.to(el.sprite.scale, speed, {
                    delay: d,
                    x: 0,
                    y: 0,
                    ease: easing,
                    onComplete: function () {
                        el.sprite.parent.removeChild(el.sprite);
                        el.sprite.destroy();
                    }
                });

            }

        },

        conf = {
            debug: false,
            color: 0xffffff,
            width: null,
            height: null,
            scale: 0.5,
            container: null,
            motifs: [],
            motifWidth: 100,
            motifHeight: 100,
            randomOrder: false,
            delay: null,
            duration: 1000,
            easingIn: null,
            easingOut: null,
            fnIn: tr.slideIn,
            fnOut: tr.slideOut,
            autoresize: true
        },

        patterns = [];



    // -- P OBJECT

    function P(c) {
        this.conf = $.extend({}, conf, c);
        this.width = this.conf.width;
        this.height = this.conf.height;
        this.active = false;
        this.init();
        // -- Implementing:
        // this.stage
        // this.renderer
        // this.$container
        // this.resolution
        // this.nEls
        // this.els
        // this.animate
        // this.tid
    }


    P.prototype = {


        // -- P.init

        init: function() {

            var that = this,
                stage = this.stage = new PIXI.Container(),
                container = this.conf.container || document.body,
                $container = this.$container = $(container),
                $window = $(window),
                resolution = this.resolution = window.devicePixelRatio,
                rendererOptions = {
                    resolution: resolution,
                    antialiasing: false,
                    transparent: false,
                    backgroundColor: conf.color
                },
                renderer,

                animate = this.animate = function animate() {
                    if (that.active) {
                        requestAnimationFrame(animate);
                        renderer.render(stage);
                    }
                };

            if (!this.width) {
                this.width = $container.width();
            }
            if (!this.height) {
                this.height = $container.height();
            }

            renderer = this.renderer = PIXI.autoDetectRenderer(this.width, this.height, rendererOptions);
            this.resize();
            if (this.conf.autoresize) {
                $window.resize(function() {
                    that.width = $container.width();
                    that.height = $window.height(); //TODO: Do we need to adapt to container?
                    that.resize();
                });
            }
            $container.append(renderer.view);
            this.active = true;
            requestAnimationFrame(animate);

            this.els = [];
            if (this.conf.motifs.length) {
                this.addElements();
            }

            //TODO: This is not so elegant
            if (!P.prototype.hasBeenInit) {
                TweenLite.to(this.renderer, 1, {delay: 0.3, colorProps: {backgroundColor: this.conf.color, format: 'number'}, ease: Linear.easeNone});
                P.prototype.hasBeenInit = true;
            }

        },


        // -- P.resize

        resize: function() {
            this.renderer.view.style.width = this.width + 'px';
            this.renderer.view.style.height = this.height + 'px';
            this.renderer.resize(this.width, this.height);
        },


        // -- P.pause

        pause: function() {
            this.active = false;
        },


        // -- P.createElement

        createElement: function(te) {

            var sprite = new PIXI.Sprite(te.texture),
                el = {
                    x: te.x,
                    y: te.y,
                    sprite: sprite,
                    n: te.i,
                    p: this
                },
                g;

            if (this.conf.debug) {
                g = new PIXI.Graphics();
                g.beginFill(0xffffff, 0.1);
                g.drawRect(el.x - this.conf.motifWidth / 2, el.y - this.conf.motifHeight / 2, this.conf.motifWidth,
                    this.conf.motifHeight);
                this.stage.addChild(g);
            }

            sprite.visible = false;
            sprite.anchor.x = 0.5;
            sprite.anchor.y = 0.5;
            sprite.scale = new PIXI.Point(0.5, 0.5);
            sprite.position.x = te.x;
            sprite.position.y = te.y;
            //sprite.blendMode = PIXI.BLEND_MODES.LIGHTEN;
            //TODO: Tidy up.
            function increase_brightness(hex, percent){
                // strip the leading # if it's there
                hex = hex.replace(/^\s*#|\s*$/g, '');

                // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
                if(hex.length == 3){
                    hex = hex.replace(/(.)/g, '$1$1');
                }

                var r = parseInt(hex.substr(0, 2), 16),
                    g = parseInt(hex.substr(2, 2), 16),
                    b = parseInt(hex.substr(4, 2), 16);

                return '' +
                    ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
                    ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
                    ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
            }
            var tint = parseInt(increase_brightness(this.conf.color.toString(16), 88), 16);
            //tint = '#' +  ("00" + tint).substr(tint.length);
            sprite.tint = tint;
            //console.log(tint.toString(16));
            //sprite.alpha = 0.9; //TODO: Make this configurable
            this.stage.addChild(sprite);

            this.conf.fnIn(el);

            return el;

        },


        // -- P.addElements

        addElements: function() {

            var that = this,
                els = this.els,
                ny = Math.ceil(this.height / this.conf.motifHeight) + 1,
                nx = Math.ceil(this.width / (this.conf.motifWidth * 2)) + ny,
                n = this.nEls = nx * ny,
                textures = {},
                pre = [],
                hasPos = false,
                i,
                j,
                xi = 0,
                motif,
                src,
                texture,
                te,
                x,
                tmp,
                tmpI,
                y = 0,
                ii = 0,

                at2 = function(s) {
                    if (that.resolution != 2) {
                        return s;
                    }
                    var S = '@2x',
                        d = s.lastIndexOf('.');
                    if (s.indexOf(S) == -1) {
                        s = s.substr(0, d) + S + s.substr(d);
                    }
                    return s;
                },

                shuffle = function(array) {
                    var currentIndex = array.length, temporaryValue, randomIndex ;
                    while (0 !== currentIndex) {
                        randomIndex = Math.floor(Math.random() * currentIndex);
                        currentIndex -= 1;
                        temporaryValue = array[currentIndex];
                        array[currentIndex] = array[randomIndex];
                        array[randomIndex] = temporaryValue;
                    }
                    return array;
                },

                getTexture = function() {
                    if (that.conf.randomOrder) {
                        ii = Math.floor(Math.random() * that.conf.motifs.length);
                    } else {
                        ii++;
                        if (ii >= that.conf.motifs.length) {
                            ii = 0;
                        }
                    }
                    motif = that.conf.motifs[ii];
                    if (!motif) {
                        src = null;
                        texture = null;
                    } else if (typeof motif == 'string') {
                        src = motif;
                        texture = PIXI.Texture.fromImage(at2(src));
                    } else if (typeof motif == 'object') {
                        src = motif.src;
                        texture = motif.src ? PIXI.Texture.fromImage(at2(src)) : null;
                        if (motif.max >= textures[src]) {
                            return getTexture();
                        }
                    }
                    textures[src] = textures[src] ? textures[src]++ : 0;
                    return {
                        motif: motif,
                        texture: texture
                    };
                };

            if (typeof this.conf.fnIn == 'string') {
                this.conf.fnIn = tr[this.conf.fnIn];
            }

            // Get all the min occurrences first and save the ones with pos
            for (i = 0; i < n; i++) {
                motif = this.conf.motifs[i];
                if (motif && typeof motif == 'object') {
                    if (motif.min) {
                        for (j = 0; j < motif.min; j++) {
                            pre.push({
                                motif: motif,
                                texture: motif.src ? PIXI.Texture.fromImage(at2(motif.src)) : null
                            });
                        }
                        if (motif.src) {
                            textures[motif.src] = textures[motif.src] ? textures[motif.src]++ : 0;
                        }
                    }
                    if (motif.pos) {
                        hasPos = true;
                    }
                }
            }

            while (pre.length < n) {
                pre.push(getTexture());
            }

            if (this.conf.randomOrder) {
                shuffle(pre);
            }

            if (hasPos && n > 100) {
                for (i = 0; i < n; i++) {
                    te = pre[i];
                    if (te.motif && te.motif.pos) {
                        tmpI = Math.floor(n / 2) + te.motif.pos[0];
                        tmpI += te.motif.pos[1] * nx + (ny % 2 ? 0 : -Math.ceil(nx / 2));
                        tmp = pre[tmpI];
                        pre[tmpI] = te;
                        pre[i] = tmp;
                    }
                }
            }

            for (i = 0; i < n; i++) {
                x = xi * this.conf.motifWidth * 2 + (y / this.conf.motifHeight % 2) * this.conf.motifWidth - ny * this.conf.motifWidth;
                te = pre[i];
                if (!te) {
                    continue;
                }
                te.x = x;
                te.y = y;
                te.i = i;
                els.push(this.createElement(te));
                xi++;
                if (xi >= nx) {
                    xi = 0;
                    y += this.conf.motifHeight;
                }
            }

        },


        // -- P.clearElements

        clearElements: function() {

            var els = this.els,
                i;

            if (typeof this.conf.fnOut == 'string') {
                this.conf.fnOut = tr[this.conf.fnOut];
            }

            for (i = 0; i < els.length; i++) {
                this.conf.fnOut(els[i]);
            }

            this.els = [];

        },


        // -- P.transform

        transform: function(c, d) {
            var that = this;
            this.active = true;
            this.clearElements();
            $.extend(this.conf, c);
            clearTimeout(this.tid);
            this.tid = setTimeout(function() {
                that.addElements();
            }, d);
            TweenLite.to(this.renderer, 1, {colorProps: {backgroundColor: this.conf.color, format: 'number'}, ease: Linear.easeNone});
        },


        // -- P.reset

        reset: function(c) {
            var i,
                el;
            for (i = 0; i < this.els.length; i++) {
                el = this.els[i];
                el.sprite.parent.removeChild(el.sprite);
                el.sprite.destroy();
            }
            this.els = [];
            $.extend(this.conf, c);
            this.active = true;
            requestAnimationFrame(this.animate);
            this.renderer.backgroundColor = this.conf.color;
            exports.PP = this;
        }


    };


    // -- MOLAPATTERN METHODS

    function create(conf) {
        var p = new P(conf);
        patterns.push(p);
        return p;
    }


    // -- EXPORTS

    exports.Molapattern = {
        create: create,
        tr: tr
    };


})(this);