Molapp.Views = Molapp.Views || {};

Molapp.Views.Project = function(dom) {
    this.$dom = $(dom);
    this.$pattern = null;
};

Molapp.Views.Project.prototype = {

    init: function() {
        var that = this,
            $window = this.$window = $(window),
            $desktop = $('html.no-touch'),
            //$window_height,
            $intro = this.$intro = $('.intro>article'),
            $header = this.$header = $('.intro>article>header'),
            $pattern = this.$pattern = $('#main-patterns'),
            player = this.player,
            $header_top,
            $casefilm = $(".video-advanced"),
            $casefilm_top,
            $casefilm_width,
            $casefilm_height,
            resize = this.resize = function() {
                $header_top = $header.height()*0.3;
                if ($casefilm && $casefilm.offset()) {
                    $casefilm_top = $casefilm.offset().top;
                }
                $casefilm_width = $('iframe', $casefilm).width() / 2 + 25 + 140;
                if ($window.width() > 1550){
                    $casefilm_width += ($window.width() - 1550) / 2;
                }
                $casefilm_height = $('iframe', $casefilm).height() / 2 + 140;
                updateHeader();
            },
            updateHeader = function() {
                if ($window.scrollTop() > $header_top) {
                    $intro.addClass('open');
                    $pattern.addClass('open');
                } else {
                    $header.removeClass('open');
                    $pattern.removeClass('open');
                }
            },
            throttle = this.throttle = (function() {
                var timeWindow = 40,
                    lastExecution = new Date((new Date()).getTime() - timeWindow),
                    fnThrottle = function (args) {
                        updateHeader();
                        if ($casefilm[0]){
                            that.player.api('pause');
                            $casefilm.removeClass('open');
                        }
                    };
                return function() {
                    if ((lastExecution.getTime() + timeWindow) <= (new Date()).getTime()) {
                        lastExecution = new Date();
                        return fnThrottle.apply(this, arguments);
                    }
                };
            }());
        $window.on('resize', resize);
        $window.on('scroll', throttle);
        //$(function() {
        if ($casefilm[0]){
            //console.log($casefilm[0]);
            var id = $('iframe', $casefilm).attr('id');
            //console.log(id);
            var iframe = $('#' + id)[0];
            this.player = $f(iframe);

            // When the player is ready, add listeners for pause, finish, and playProgress
            this.player.addEvent('ready', function() {
                that.player.addEvent('pause', onPause);
                that.player.addEvent('finish', onFinish);
                //player.addEvent('playProgress', onPlayProgress);
            });

            // Call the API when a button is pressed
            var playClick = this.playClick = $('.play-button', $casefilm).click(function() {
                that.player.api('play');
                $casefilm.addClass('open');
            });
            if ($desktop[0]){
                var mouseMove = this.mouseMove = $('.play-button', $casefilm).mousemove(function(e) {
                    TweenMax.to($('.shape', this), 1, {x: (e.pageX - $casefilm_width * 2) / 2 + $casefilm_width / 2, y: (e.pageY - $casefilm_top - $casefilm_height * 2) / 2 + $casefilm_height / 2, ease: Quart.easeOut});
                });

                var mouseOut = this.mouseOut = $('.play-button', $casefilm).mouseout(function(e) {
                    TweenMax.to($('.shape', this), 1, {x: -70, y: -70, ease: Quart.easeOut});
                });
            }

            function onPause(id) {
                $casefilm.removeClass('open');
            }

            function onFinish(id) {
                $casefilm.removeClass('open');
            }

            //function onPlayProgress(data, id) {
                //status.text(data.seconds + 's played');
            //}
        }
        //});
    },

    destroy: function() {
        this.$pattern.removeClass('open');
        this.$header.removeClass('open');
        this.$window.off('scroll', this.throttle);
        this.$window.off('resize', this.resize);
        this.$window.off('click', this.playClick);
        this.$window.off('mousemove', this.mouseMove);
        this.$window.off('mouseout', this.mouseOut);
        //TODO: Remove player events properly
        /*this.player.removeEventListener('ready');
        this.player.removeEventListener('pause');
        this.player.removeEventListener('finish');*/
    },

    doIn: function() {
        var that = this;
        setTimeout(function() {
            that.$intro.removeClass('hidden');
            $('.content', that.$header).show();
            that.$pattern.removeClass('hidden');
        }, 100);
    },

    doOut: function() {
        this.$intro.addClass('hidden');
        $('.content', this.$header).hide();
        this.$pattern.addClass('hidden');
    }

};