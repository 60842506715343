var Molapp = window.Molapp = {};

Molapp.events = {
    ready: "ready.molapp",
    transitionReady: "transitionReady.molapp"
};


Molapp.session = {
    scrollTop: 0
};

Molapp.init = function() {

    function getQueryVariable(variable)
    {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return(false);
    }
    var source = getQueryVariable("utm_source")
    if (source == 'designernews'){
        console.log('hey');
        $('.box.intro h2').html('We are a digital&nbsp;panther');
    };

    Molapp.Tracker.init(Zumo);

    $(document).trigger(Molapp.events.ready);

    svg4everybody(); //Polyfill: External SVG's

    Molapp.PatternManager.init();
    Molapp.MenuManager.init();

};