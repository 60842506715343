Molapp.Utils = {

    rgb2hex: function (rgb) {
        var hexDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
        function hex(x) {
            return isNaN(x) ? '00' : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
        }
        rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        return parseInt('0x' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]), 16);
    }

};