(function(window) {

    // Check for Zumo and pushState
    if (typeof Zumo != "object" || typeof history.pushState != "function")
        return;


    // *** HISTORY - OBJECT

    var History = {

        defaultPage: null,
        goAtLoad: true,

        init: function() {

            var that = this,

                go = this.go = function() {
                    gotoPath(History.getPath());
                },

                gotoPath = function(path, e) {

                    if (Zumo.props && Zumo.props.defaultPage)
                        that.defaultPage = Zumo.props.defaultPage;

                    if (path.length && Zumo.getPageContext(path)) {
                        Zumo.go(path, {originalEvent: e});
                    } else if (that.defaultPage) {
                        Zumo.go(that.defaultPage, {originalEvent: e});
                    }

                },

                linkHandler = function(e) {
                    var p;
                    //TODO: Fix this hack wich is specific for molamil.com
                    if (typeof e.currentTarget.host == "string" && /:8080|molamil.com/g.test(e.currentTarget.host) &&
                        typeof e.currentTarget.pathname == "string" && e.currentTarget.pathname.charAt(0) == "/") {
                        p = e.currentTarget.pathname.split('/');
                        gotoPath(p[p.length - 1], e);
                        e.preventDefault();
                        return false;
                    }
                };

            window.onpopstate = function(event) {
                var s = Molapp.session.scrollTop;
                if (event.state) {
                    Zumo.go(event.state.id);
                    setTimeout(function() {
                        window.scrollTo(0, s);
                    }, 1);
                    event.preventDefault();
                }
                return false;
            };

            Zumo.observe("onConfLoaded", function() {
                if (that.goAtLoad) {
                    go();
                }
            }, -1);

            Zumo.observe("onPageDisplay", function(master) {
                //TODO: Consider adding the title and merging the request
                if ((!history.state || history.state.id != master.context.id) && master.context.props._deepLink != "false") {
                    window.scrollTo(0, 0);
                    history.pushState(master.context, "", master.context.id);
                    window.scrollTo(0, Molapp.session.scrollTop);
                }
            });

            if (typeof $ == "function") {
                $(function() {
                    $("a").click(linkHandler);
                });
                Zumo.observe("onPageInit", function(master) {
                    var $a = $("a", master.target);
                    $a.off("click", linkHandler);
                    $a.on("click", linkHandler);
                });
            }

        },

        getPath: function() {
            var a = window.location.pathname.split('/');
            return a[a.length - 1];
        }

    };


    // *** INIT

    Zumo.History = History;
    History.init();


})(this);